<template>
	<UAlert v-if="data && data?.length > 0" :ui="{ rounded: 'rounded-none', wrapper: 'overflow-visible w-full relative', icon: { base: 'w-10 h-10 flex-shrink-0'}}"
	        icon="i-fa6-solid-clock-rotate-left" color="amber" variant="soft" :actions
	        description="New data is currently being uploaded. Certain data may appear incompleted until the process is finished" />
</template>

<script setup lang="ts">
import type {Database} from "~/types/database";
import type {AlertAction} from "#ui/types";

const supabase = useSupabaseClient<Database>()

const toast = useToast()

const actions: AlertAction[] = [
	{variant: 'solid', color: 'amber', label: 'View Status', to: '/data/upload', trailing: true },
]

const { data, pending, error, refresh } = await useAsyncData(
	'file-upload-alert',
	async () => {
		const { data, error } = await supabase
			.from('uploads')
			.select('*')
			.eq('status', 'Processing')
		if (error) {
			throw error
		}
		if (data) return data
	}
)

supabase
	.channel('uploadAlerts')
	.on('postgres_changes', { event: '*', schema: 'public', table: 'uploads' }, async payload => {
		await refresh()
		console.log(payload.new.status)
		if (payload.new.status === 'Processing') {
			toast.add({title: 'File upload started', description: `New ${payload.new.type} data is currently being processed`, color: 'amber', icon: 'i-fa6-solid-clock-rotate-left'})
		}
		if (payload.new.status === 'Completed') {
			toast.add({title: 'File upload completed', description: `New ${payload.new.type} data has been completed`, color: 'green', icon: 'i-fa6-solid-check'})
		}
		if (payload.new.status === "Failed") {
			toast.add({title: 'File upload failed', description: `${payload.new.type} data upload failed`, color: 'red', icon: 'i-fa6-solid-xmark'})
		}
	})
	.subscribe()


</script>